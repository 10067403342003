.Header {
    display: flex;
    flex-direction: row;
    z-index: 1;
    background-color: #222831;
    justify-content: space-between;
    padding: 20px 40px 20px 40px;
    align-items: center;
}

.HeaderNavButton {
    font-family: "Source Code Pro";
    margin: 0px;
    background-color: #ffffff1a;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 10px;
}

.HeaderNavButton:hover {
    background-color: #ffffff11;
}

.SelectedHeaderNavButton {
    
}


.Theme {
    margin-inline-start: 40px;
}