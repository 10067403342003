/* divs */

.inline-div {
    display: inline;
    /* Additional styles */
}

.make-child-center {
    align-items: center;
    justify-content: center;
}

/* fonts */

.codeFont {
    font-family: "Source Code Pro", monospace;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.cursiveFont {
    font-family: "Abril Fatface", serif;
    font-weight: 100;
    font-style: normal;
}

/* Highlighted Background */
.HighlitedBackground {
    background-color: blueviolet;
}

.logo a {
    color: white;
    text-decoration: none;
}

.clickable-text {
    @apply text-white font-mono no-underline;
  }
  
  .clickable-text:hover {
    @apply italic text-white no-underline border-b border-white;
  }