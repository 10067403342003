:root {
    --social-icon-size: 14px;
}

.FooterBody {
    padding: 20px;
    background-color: #1A1E25;
}

/* Icon styling */
.SocialIcon {
    fill: white;
    width: var(--social-icon-size);
    height: var(--social-icon-size);
}


.FooterBase {
    display: flex;
    background-color: #161A20;
    padding: 20px;
}

.SocialTitle {
    font-size: small;
    /* margin-left: 4px; */
    color: white;
}

.SiteLink {
    display: inline-flex;
    font-family: "Source Code Pro";
    color: white;
    align-items: center;
    text-decoration: none;
    border-bottom: 0.5px solid white;
    padding-bottom: 2px;
}

.SocialLinks {
    display: flex;
    gap: 50px;
}

.Socials{
    padding: 0px 100px 0px 100px;
    display: flex;
    justify-content: space-between;
}